import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleDefinitionService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  /*********************Master Role List Service****************************/
  getMasterRoleList() {
    return this.http.get(this.serverurl + 'api/role/masterRoleList')
  }

  /*********************Module Access Role List****************************/
  getModuleAccessRoleList() {
    return this.http.get(this.serverurl + 'api/role/roleListForUser')
  }

  // Create Role
  createRole(postData: any) {
    return this.http.post(this.serverurl + 'api/role/create', postData)
  }

  // Edit Role
  editRole(postData: any) {
    return this.http.post(this.serverurl + 'api/role/edit', postData)
  }

  // private extractData(res: Response) {
  //   let body = res.json();
  //   return body || {};
  // }
}
