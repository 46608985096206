import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserRoleDefinitionService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  //  Role
  getUserRoleDefinitionList() {
    return this.http.get(this.serverurl + 'api/role/roleList')
  }

  // Dashboard
  getDashboardData() {
    return this.http.get(this.serverurl + 'api/users/dashboard')
  }

  // Role
  getSearchUserRole(a) {
    return this.http.get(this.serverurl + 'api/role/roleList', {
      params: {
        search: a
      }
    })
  }

  // User Definition
  getUserDefinitionList(a, b, c): Observable<any> {
    return this.http.get(this.serverurl + 'api/users/userList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchUser(a, b, c, d) {
    console.log('------------------>', a, b, c, d)
    return this.http.get(this.serverurl + 'api/users/userList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  // Create User
  createUser(postData: any) {
    return this.http.post(this.serverurl + 'api/users/create', postData)
  }

  // Edit Role
  editUser(postData: any) {
    return this.http.post(this.serverurl + 'api/users/edit', postData)
  }

}
