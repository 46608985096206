import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  getOrderList(a, b, c): Observable<any> {
    return this.http.get(this.serverurl + 'api/order/orderList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchOrderList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/order/orderList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getOrderStatusList() {
    return this.http.get(this.serverurl + 'api/order/orderStatusList')
  }

  getOrderDetails(orderId) {
    return this.http.get(this.serverurl + `api/order/orderDetails?OrderId=${orderId}`)
  }

  getPaymentStatusList() {
    return this.http.get(this.serverurl + 'api/order/orderPaymentModeList')
  }

  updateOrderStatus(postData: any) {
    return this.http.post(this.serverurl + 'api/order/updateStatus', postData)
  }
}
